import React from 'react';
import LoginBg from '../assets/bg_login.jpg';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

export default function LoginPage(){
    return(
        <>
        <div className="w-full min-h-screen bg-[url('./assets/bg_login.jpg')] bg-no-repeat bg-cover bg-center">
          <div className='grid grid-cols-1 sm:grid-cols-2 h-screen w-full'>
            <div className="flex flex-col justify-center">
              <div className="flex items-end">
                <div className="bg-[#1e0f9e] rounded-3xl max-w-[98%] sm:max-w-[400px] ml-[1%] sm:ml-auto p-8">
                  <form className="">
                    <img className="py-16 max-w-[60%] mx-auto" src={Logo} alt="" />
                    <div className="mb-2">
                      <input className="flex border m-auto rounded-full bg-[#1e0f9e] border border-[#37e8dd] text-white text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-2.5 px-4 font-montserrat w-48" placeholder="user" type="text" />
                    </div>
                    <div>
                      <input className="flex border m-auto rounded-full bg-[#1e0f9e] border border-[#37e8dd] text-white text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block py-2.5 px-4 font-montserrat w-48" placeholder="password" type="password" />
                    </div>
                    <div className="mx-auto">
                      <Link to="/">
                        <button className="flex border my-5 py-2.5 hover:bg-[#1e0f9e] mx-auto px-8 rounded-full bg-[#37e8dd] hover:text-[#37e8dd] text-[#1e0f9e] text-xs hover:border-white font-montserrat font-semibold w-48 justify-center">Login</button>
                      </Link>
                    </div>
                    <div className="flex justify-center">
                      <button className="text-xs font-montserrat text-white mb-4 mt-8 font-light hover:text-[#37e8dd]">Esqueceu o Acesso?</button>
                    </div>
                    <div>
                      <p className="text-xs font-montserrat mb-4 mt-1 font-light text-[#37e8dd] text-center pb-10">Não tem Acesso? Solicite para o email <button className="text-xs font-montserrat text-white mb-4 mt-1 font-light hover:text-[#37e8dd] text-center">info@digifycausasxxi.com</button></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-end'>
              <p className="flex flex-end align-bottom text-xs font-montserrat text-white font-light mb-2 px-6 m-auto sm:m-0 sm:mb-2">Todos os Direitos Reservados @2023</p>
            </div>
          </div>
        </div>
        </>
    )
}
