import React from 'react';
import Logo from '../../assets/logo.png';

export default function HighlightItem(){
    return(
        <>
        <div className="mt-2.5 mb-1.5">
          <p className="text-[#223198] text-sm font-montserrat font-semibold mb-1">Lorem Ipsum is simply dummy</p>
          <p className="text-gray-700 text-xs font-montserrat">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vestibulum.
          </p>
        </div>
        </>
    )
}
