import LoginPage from './pages/Login';
import DashboardPage from './pages/Dashboard';
import ModulePage from './pages/Module';
import ModuleListPage from './pages/ModuleList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<DashboardPage />} />
        <Route path="/module" element={<ModulePage />} />
        <Route path="/modulelist" element={<ModuleListPage />} />
      </Routes>
    </Router>
  );
}

export default App;
