import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import HighlightItem from '../components/dashboard/HighlightItem';
import Highlight1 from '../assets/highlight1.jpg';
import ModuleItem from '../components/dashboard/ModuleItem';
import Modulo from '../assets/icon_modulo.png';
import Time from '../assets/time.png';
import IconPDF from '../assets/icon_pdf.png';
import IconMP4 from '../assets/icon_mp4.png';
import IconLink from '../assets/icon_link.png';


export default function ModulePage(){
    return(
        <>
        <div className="w-full min-h-screen">
          <Header />
          <div className="container mx-auto bg-white md:pt-28 pt-24">
            <div className="flex flex-col md:flex-row w-full h-full mb-16">
              <div className="rounded-3xl mx-2 md:min-w-[200px] xl:min-w-[250px] bg-[#223198] border-2 border-[#223198] text-center mb-4 md:mb-0">
                <div className="w-full mb-1">
                  <p className="w-full mb-1 pt-6 text-sm font-montserrat font-semibold tracking-tight text-white">MÓDULO</p>
                </div>
                <div className="w-full mb-4">
                  <p className="w-full mb-1 text-sm font-montserrat font-light tracking-tight text-[#37e8dd]">SESSÕES QWERTY</p>
                </div>
                <div className="w-full pt-4 mb-6 text-sm font-montserrat font-light tracking-tight text-[#37e8dd]">
                  <img className="p-3 w-28 mx-auto mb-8" src={Modulo} alt="" />
                </div>

              </div>
              <div className="flex flex-col mx-2 md:flex-row md:flex-grow rounded-3xl bg-white border-2 border-[#37e8dd]">
                <div className="px-2 pb-4 flex flex-col sm:flex-grow">
                  <div className="text-[#223198] pt-6 px-1 md:mt-1">
                    <p className="text-[#223198] text-2xl font-montserrat font-semibold mb-1">Labirinto: Sequência</p>
                  </div>
                  <div>
                    <div className="text-[#223198] text-black px-2 py-2 rounded-full text-center inline-flex items-center text-center w-full">
                      <img className="w-8" src={Time} alt="" />
                      <span className="ml-2 font-montserrat font-medium text-xs flex flex-grow">30 minutos</span>
                      <span className="ml-2 font-montserrat font-medium text-xs text-[#92b8e8] flex flex-shrink">O Tempo básico da aula inclui apenas a atividade</span>
                    </div>
                  </div>
                  <div>
                    <p className="font-montserrat font-medium text-xs p-2 text-gray-800">
                      Os alunos escrevem programas (algoritmos para o computador) que levam a personagem pelo labirinto. Eles vão perceber a importância da sequência nos programas que escrevem.
                      Interação com Robôs.
                    </p>
                    <p className="font-montserrat font-semibold text-xs p-2 pb-0 text-[#223198]">
                      RESUMO DA AULA
                    </p>
                    <p className="font-montserrat font-medium text-xs p-2 text-gray-500">
                      Nesta série de puzzles, os alunos vão expandir o seu conhecimento acerca dos algoritmos, aprendido nas aulas offline. Com as personagens dos Angry Birds, os alunos vão desenvolver algoritmos sequências para deslocar um pássaro de um lado do labirinto até ao porco do outro lado.
                    </p>
                    <p className="font-montserrat font-semibold text-xs p-2 pb-0 text-[#223198]">
                      SUMÁRIO DA AULA
                    </p>
                    <p className="font-montserrat font-medium text-xs p-2 text-gray-500">
                      - Início<br />
                      -Introdução<br />
                      -Atividade: Labirinto: Sequências<br />
                      -Labirinto: Sequências<br />
                      -Extensão da Aprendizagem<br />
                      -Atividades<br />
                    </p>
                    <p className="font-montserrat font-semibold text-xs p-2 pb-0 text-[#223198]">
                      OBJETIVOS DA AULA
                    </p>
                    <p className="font-montserrat font-medium text-xs p-2 text-gray-500">
                      Os alunos vão:<br />
                      • Ordenar comandos de movimento como passos sequencias num programa;<br />
                      • Representar um algoritmo como um programa de computador;<br />
                      • Contar o número de vezes que uma ação deveria ser executada e representá-la como uma instrução num programa.
                    </p>
                    <p className="font-montserrat font-semibold text-xs p-2 pb-0 text-[#223198]">
                      COMECANDO (15 MIN)
                    </p>
                    <p className="font-montserrat font-medium text-xs p-2 text-gray-500">
                      Introdução<br />
                      Pergunta aos alunos se estão familiarizados com o jogo Angry Birds. Explica-lhes que vão escrever programas para ajudar um Angry Bird a localizar o porco.<br />
                      • Revê as direções cardinais (Norte; Sul, Este e Oeste).<br />
                      • Indica aos alunos que vão ver as letras dos cardeais no seu programa ao lado das
                      setas das direcões.<br />
                      • Levar o pássaro ao porco vai requerer colocar as direções numa ordem especifica
                      ou sequência.<br />
                      • Consegues resolver os puzzles com o menor número de blocos possiveis?
                    </p>
                    <p className="font-montserrat font-semibold text-xs p-2 pb-0 text-[#223198]">
                      ATIVIDADE
                    </p>
                    <p className="font-montserrat font-medium text-xs p-2 text-gray-500">
                      Labirinto: Sequência<br />
                      Enquanto os alunos navegam nos puzzles, observa como planeiam o caminho do pássaro. Identifica as diferentes estratégias utilizadas e pede aos alunos para as partilharem com o grupo. Isto ajuda os alunos a reconhecer que existem muitas formas de resolver estes problemas. Podes querer mostrar alguns puzzies no projector.<br />
                      Enquanto fazes isto podes pedir a um aluno para traçar o caminho no ecrã enquanto outro escreve as direções no quadro:
                    </p>
                    <p className="font-montserrat font-semibold text-xs p-2 pb-0 text-[#223198]">
                      EXTENSÃO DA APRENDIZAGEM
                    </p>
                    <p className="font-montserrat font-medium text-xs p-2 text-gray-500">
                      Usa estas atividades para potenciar a aprendizagem dos alunos.<br />
                      Computador Humano<br />
                      Em grupos pequenos, deixa os alunos desenhar os seus labirintos e desafiarem-se uns aos outros a escrever programas para os resolver. Para mais diversão, faz labirintos em tamanho real com os alunos a fazerem de Angry Bird e Porco.
                    </p>
                  </div>
                </div>
                <div className="pb-4 flex flex-col md:border-t-0 bg-[#37e8dd] rounded-tr-2xl rounded-br-2xl md:min-w-[250px] xl:min-w-[300px] w-full">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                      <div className="mx-auto pt-6 pb-4">
                        <div className="flex flex-row flex-grow w-full">
                          <p className="text-[#223198] text-xs font-montserrat font-medium mt-4 text-center mx-auto mb-1">
                            COMPETÊNCIAS
                          </p>
                        </div>
                        <div className="flex flex-row flex-grow w-full">
                          <p className="px-4 text-gray-800 text-xs font-montserrat font-medium mt-2 mx-auto">
                            <button className="bg-gray-200 text-gray-600 px-6 py-2 rounded-full">Loop</button>
                          </p>
                        </div>
                        <div className="flex flex-row w-full">
                          <p className="px-4 text-gray-800 text-xs font-montserrat font-medium mt-2 mx-auto">
                            <button className="bg-gray-200 text-gray-600 px-6 py-2 rounded-full">Loop</button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mx-auto pt-6 pb-4">
                        <div className="flex flex-row w-full">
                          <p className="text-[#223198] text-xs font-montserrat font-medium mt-4 text-center mx-auto mb-1">
                            CONHECIMENTOS
                          </p>
                        </div>
                        <div className="flex flex-row w-full">
                          <p className="px-4 text-gray-800 text-xs font-montserrat font-medium mt-2 mx-auto">
                            <button className="bg-[#223198] text-white px-6 py-2 rounded-full">Loop</button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mx-auto pt-6 pb-4">
                        <div className="flex flex-row w-full">
                          <p className="text-[#223198] text-xs font-montserrat font-medium mt-4 text-center px-6 mx-auto mb-4">
                            DOCUMENTOS DE APOIO
                          </p>
                        </div>
                        <div className="flex flex-row w-full">
                          <div className="w-full flex flex-col">
                            <div className="mx-2">
                              <button className="bg-[#fcb670] hover:bg-grey text-[#223198] text-black px-2 py-2 rounded-full text-center inline-flex items-center text-center w-full">
                                <img className="w-4 ml-2" src={IconPDF} alt="" />
                                <span className="ml-2 font-montserrat font-semibold text-xs">PDF 1</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row w-full mt-2">
                          <div className="w-full flex flex-col">
                            <div className="mx-2">
                              <button className="bg-[#fcb670] hover:bg-grey text-[#223198] text-black px-2 py-2 rounded-full text-center inline-flex items-center text-center w-full">
                                <img className="w-4 ml-2" src={IconPDF} alt="" />
                                <span className="ml-2 font-montserrat font-semibold text-xs">PDF 2</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row w-full mt-2">
                          <div className="w-full flex flex-col">
                            <div className="mx-2">
                              <button className="bg-[#a3f2a1] hover:bg-grey text-[#223198] text-black px-2 py-2 rounded-full text-center inline-flex items-center text-center w-full">
                                <img className="w-4 ml-2" src={IconMP4} alt="" />
                                <span className="ml-2 font-montserrat font-semibold text-xs">Video 1</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mx-auto pt-6 pb-4">
                        <div className="flex flex-row w-full">
                          <p className="text-[#223198] text-xs font-montserrat font-medium mt-4 text-center px-6 mx-auto mb-4">
                            LINKS DE APOIO
                          </p>
                        </div>
                        <div className="flex flex-row w-full">
                          <div className="w-full flex flex-col">
                            <div className="mx-2">
                              <button className="bg-white hover:bg-grey text-[#223198] text-black px-2 py-2 rounded-full text-center inline-flex items-center text-center w-full">
                                <img className="w-4 ml-2" src={IconLink} alt="" />
                                <span className="ml-2 font-montserrat font-semibold text-xs">Link 1</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row w-full mt-2">
                          <div className="w-full flex flex-col">
                            <div className="mx-2">
                              <button className="bg-white hover:bg-grey text-[#223198] text-black px-2 py-2 rounded-full text-center inline-flex items-center text-center w-full">
                                <img className="w-4 ml-2" src={IconLink} alt="" />
                                <span className="ml-2 font-montserrat font-semibold text-xs">Link 2</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      <div className="mx-auto pt-6 pb-4">
                        <div className="flex flex-row w-full">
                          <p className="text-[#223198] text-xs font-montserrat font-medium mt-4 text-center px-6 mb-1">
                            DEIXAR UMA OBSERVAÇÃO
                          </p>
                        </div>
                        <div className="flex flex-row w-full">
                          <div className="w-full flex flex-col">
                            <div className="mx-2">
                              <textarea
                                className="
                                  form-control
                                  block
                                  w-full
                                  px-3
                                  py-3
                                  text-base
                                  text-gray-700
                                  bg-gray-200 bg-clip-padding
                                  border border-solid border-gray-300
                                  rounded-3xl
                                  transition
                                  ease-in-out
                                  m-0
                                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                  text-xs
                                  font-montserrat
                                "
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Observação"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mx-auto pt-2 pb-4">
                        <div className="flex flex-row w-full">
                          <p className="text-[#223198] text-xs font-montserrat font-medium mt-4 text-center px-6 mb-1">
                            AVALIAÇÃO DA AULA
                          </p>
                        </div>
                        <div className="flex flex-row w-full">
                          <div className="w-full flex flex-col">
                            <div className="mx-2 text-center mt-20">
                              <button className="bg-[#223198] text-white px-6 py-2 rounded-full text-xs font-montserrat font-medium mx-auto">SUBMETER</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mx-auto pt-4 pb-4">
                        <div className="flex flex-col w-full">
                          <p className="text-[#223198] text-xs font-montserrat font-medium mt-4 text-center px-6 mb-1">
                            VALIDAÇÃO DE AULA
                          </p>
                        </div>
                        <div className="w-full">
                          <div className="mt-4 mx-auto text-center">
                            <p className="text-gray-800 text-xs font-montserrat font-semibold mt-4 text-center mb-3">
                              Pergunta 1
                            </p>
                          </div>
                          <div className="mt-2 mx-auto text-center">
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input type="checkbox" value="" className="sr-only peer" />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="mt-4 mx-auto text-center">
                            <p className="text-gray-800 text-xs font-montserrat font-semibold mt-4 text-center mb-3">
                              Pergunta 2
                            </p>
                          </div>
                          <div className="mt-2 mx-auto text-center">
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input type="checkbox" value="" className="sr-only peer" />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="mt-4 mx-auto text-center">
                            <p className="text-gray-800 text-xs font-montserrat font-semibold mt-4 text-center mb-3">
                              Pergunta 3
                            </p>
                          </div>
                          <div className="mt-2 mx-auto text-center">
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input type="checkbox" value="" className="sr-only peer" />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-row w-full">
                          <div className="w-full flex flex-col mb-10">
                            <div className="mx-2 text-center mt-10">
                              <button className="bg-[#223198] text-white px-6 py-2 rounded-full text-xs font-montserrat font-medium mx-auto">SUBMETER</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="md:pt-0 flex flex-col justify-start md:w-100 w-full md:border-t-0 bg-[#37e8dd] rounded-tr-2xl rounded-br-2xl">
                  <p className="pt-6 px-4 text-gray-800 text-xs font-montserrat font-medium mt-1  pb-4 ">PRÓXIMA AULA/PROJETO</p>
                </div> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
        </>
    )
}
