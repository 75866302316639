import React from 'react';

export default function Footer(){
    return(
        <>
        <footer className="mt-0 fixed w-full z-10 bottom-0 h-12 bg-[#223198]">
          <div className="text-center text-white p-4 font-montserrat text-sm font-light">
            Todos os direitos reservados a Causas XXI | 2023
          </div>
        </footer>
        </>
    )
}
