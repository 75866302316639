import React from 'react';
import Modulo from '../../assets/icon_modulo.png';
import Cadeado from '../../assets/cadeado.png';
import { Link } from 'react-router-dom';

export default function ModuleItem(props){
  const isLocked = props.locked;
    return(
        <>
        <div className="mt-1 md:mt-2.5 mx-1">
        <div className={`rounded-2xl bg-[#223198] ${isLocked ? 'opacity-70' : 'opacity-100'}`}>
            <div className="px-6 py-4 text-center">
                <p className="mb-1 text-sm font-montserrat font-semibold tracking-tight text-white">MÓDULO</p>
                <p className="mb-4 text-sm font-montserrat font-light tracking-tight text-[#37e8dd]">SESSÕES QWERTY</p>
                <img className="p-3 w-28 mx-auto mb-8" src={Modulo} alt="" />
                {
                  props.locked && <img className="p-3 w-12 mx-auto mb-4" src={Cadeado} alt="" />
                }
                {
                  !props.locked && <p className="px-4 text-gray-800 text-xs font-montserrat font-medium mt-10 mb-6">
                    <Link to="/modulelist">
                      <button className="bg-gray-200 text-gray-600 px-6 py-2.5 rounded-full mr-2">CONTINUAR</button>
                    </Link>
                  </p>
                }
            </div>
          </div>
        </div>
        </>
    )
}
