import React from 'react';
import Modulo from '../../assets/icon_modulo.png';
import Cadeado from '../../assets/cadeado.png';
import { Link } from 'react-router-dom';

export default function ProjectoItem(props){
  const isLocked = props.locked;
  const isDone = props.isDone;
  const isChecked = props.isChecked;
  const isActive = props.isActive;
  const title = props.title;
  const description = props.description;
    return(
        <>
        <div className="mb-2 mx-2">
          <Link to="/module">
            <div className={`rounded-tr-2xl rounded-br-2xl bg-[#37e8dd] ${isDone ? 'opacity-70' : 'opacity-100'}`}>
              <div className="">
                <div className="px-4 pt-4 text-left w-full flex flex-row">
                  <div className="flex flex-grow">
                    <div className="w-full">
                      <p className="mb-1 text-sm font-montserrat font-semibold tracking-tight text-grey-800">{title}</p>
                      <p className="mb-4 text-xs font-montserrat font-light tracking-tight text-grey-800">{description}</p>
                    </div>
                  </div>
                  <div className="flex mb-2">
                    <div className="flex flex-col">
                      <div className="flex w-full">
                        <div className="w-full flex flex-row pl-2 content-end">
                          <div className="text-grey-800 text-2xl flex-grow text-right content-end">
                            {
                              isLocked && isActive && <img className="ml-auto w-4 mr-1 mb-2" src={Cadeado} alt="" />
                            }
                            {
                              !isLocked && isActive && <p>*</p>
                            }
                          </div>
                          <div className={`w-6 h-6 bg-white ${!isChecked && isLocked ? 'opacity-70' : 'opacity-100'} rounded-full ml-2 mt-0 mb-2`}>
                            {
                              isChecked && <div className="w-4 h-4 bg-[#223198] rounded-full ml-1 mt-1">
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full">
                        <div className="w-full flex flex-col pl-2">
                          <div className="text-white text-sm text-right">
                            <p className="text-gray-800 text-xs font-montserrat font-semibold">
                              <button className="bg-gray-200 text-gray-600 px-6 py-2 rounded-full mt-2">Loop</button>
                              <button className="bg-[#223198] text-white px-6 py-2 rounded-full mt-2">Loop</button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        </>
    )
}
