import React from 'react';
import Logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

export default function Header(){
    return(
        <>
          <nav className="mt-0 fixed w-full z-10 top-0 h-20">
             <div className="flex flex-wrap items-center h-20">
                 <div className="flex w-full md:w-1/2 justify-center md:justify-start text-black font-montserrat bg-[#37e8dd] h-20 hidden md:block">
                  <div className="flex flex-start justify-center h-full">
                    <div className="flex items-center text-white px-8 w-full">
                      <div className="mx-auto flex flex-column justify-center w-full">
                        <div className="">
                          <button className="bg-[#223198] px-10 py-1.5 rounded-full font-montserrat font-medium text-sm">
                            Turma 2B
                          </button>
                        </div>
                        <div className="">
                          <p className="pl-4 pr-8 py-0 font-montserrat font-medium text-xs text-[#223198]">
                            Progresso do Programa
                          </p>
                        </div>
                        <div className="flex items-center w-full">
                          <div className="h-6 relative rounded-full overflow-hidden w-full border-2 border-[#223198]">
                              <div className="w-full h-full bg-[#37e8dd] absolute"></div>
                              <div className="h-full bg-white absolute w-[45%]"></div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="pl-3 py-0 font-montserrat font-medium text-xs text-[#223198]">
                            45%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
                 <div className="flex w-full content-center justify-between md:w-1/2 md:justify-end font-montserrat bg-[#223198] h-20">
                   <div className="flex flex-start grow text-white">
                     <Link to="/" className="flex flex-start text-white">
                      <img className="p-3 pl-8" src={Logo} alt="" />
                     </Link>
                   </div>
                   <div className="flex flex-end">
                     <div className="flex items-center text-white pr-8">
                       <p className="pr-4 text-sm font-montserrat hidden sm:block">Olá, <button className="text-sm font-montserrat font-semibold text-[#37e8dd]  hover:text-white text-center">Joana</button></p>
                       <Link to="/login">
                         <button className="w-12 h-12 rounded-full overflow-hidden border-2 border-white">
                           <img className="w-full h-full object-cover" src="https://images.unsplash.com/photo-1610397095767-84a5b4736cbd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" alt="" />
                         </button>
                       </Link>
                   </div>
                   </div>
                 </div>
             </div>
         </nav>
        </>
    )
}
