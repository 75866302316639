import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import HighlightItem from '../components/dashboard/HighlightItem';
import Highlight1 from '../assets/highlight1.jpg';
import ModuleItem from '../components/dashboard/ModuleItem';
import { Link } from 'react-router-dom';

export default function DashboardPage(){
    return(
        <>
        <div className="w-full min-h-screen">
          <Header />
          <div className="container mx-auto bg-white md:pt-28 pt-24">
            <div className="items-center w-full">
              <div className="flex flex-col md:flex-row rounded-3xl bg-white border-2 border-[#37e8dd] p-2.5 mx-2">
                <img className="w-full md:w-[50%] object-cover rounded-2xl" src={Highlight1} alt="" />
                <div className="px-4 pb-4 flex flex-col justify-start w-full sm:w-['50%']">
                  <p className="text-[#37e8dd] text-xs font-montserrat font-medium mt-6 md:mt-1">DESTAQUE</p>
                  <HighlightItem />
                  <HighlightItem />
                  <HighlightItem />
                  <HighlightItem />
                </div>
                <div className="pt-6 md:pt-0 pb-4 flex flex-col justify-start border-t md:border-l md:w-100 w-full md:border-t-0">
                  <p className="px-4 text-gray-800 text-xs font-montserrat font-medium mt-1">PRÓXIMA AULA/PROJETO</p>
                  <Link to="/module" className="flex flex-start w-full">
                    <button className="text-xs font-montserrat font-semibold mt-3 px-4 text-left py-2.5 border ml-2.5 rounded-full border-2 border-[#223198] w-full">
                      M1 - 04 | Labirinto: Sequência *
                    </button>
                </Link>
                  <p className="px-4 text-gray-800 text-xs font-montserrat font-medium mt-2">
                    <button className="bg-gray-200 text-gray-600 px-6 py-2 rounded-full mr-2">Loop</button>
                    <button className="bg-[#1e0f9e] text-white px-6 py-2 rounded-full mr-2">Loop</button>
                  </p>
                  <input className="border-2 rounded-full border-[#37e8dd] text-gray-600 text-xs block py-2.5 px-4 font-montserrat mt-4 ml-2.5 focus:ring-[#223198] focus:border-[#223198]" placeholder="Nome do Projeto" type="text" />
                  <p className="px-4 text-gray-800 text-xs font-montserrat font-medium mt-2">
                    <button className="bg-gray-200 text-gray-600 px-6 py-2 rounded-full mr-2">Loop</button>
                  </p>
                </div>
              </div>
            </div>
            <div className="items-center w-full mb-12 pb-12">
              <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-1 mt-6 mx-2 md:mx-0">
                <ModuleItem
                  locked={false}
                />
                <ModuleItem
                  locked={true}
                />
                <ModuleItem
                  locked={true}
                />
                <ModuleItem
                  locked={true}
                />
                <ModuleItem
                  locked={true}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
        </>
    )
}
