import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import HighlightItem from '../components/dashboard/HighlightItem';
import Highlight1 from '../assets/highlight1.jpg';
import ModuleItem from '../components/dashboard/ModuleItem';
import Modulo from '../assets/icon_modulo.png';
import Time from '../assets/time.png';
import IconPDF from '../assets/icon_pdf.png';
import IconMP4 from '../assets/icon_mp4.png';
import IconLink from '../assets/icon_link.png';
import AulasItem from '../components/modules/AulasItem';
import ProjectoItem from '../components/modules/ProjectoItem';


export default function ModuleListPage(){
    return(
        <>
        <div className="w-full min-h-screen">
          <Header />
          <div className="container mx-auto bg-white md:pt-28 pt-24">
            <div className="flex flex-col md:flex-row w-full h-full mb-12 pb-12">
              <div className="rounded-3xl mx-2 md:min-w-[200px] xl:min-w-[250px] bg-[#223198] border-2 border-[#223198] text-center mb-4 md:mb-0">
                <div className="w-full mb-1">
                  <p className="w-full mb-1 pt-6 text-sm font-montserrat font-semibold tracking-tight text-white">MÓDULO</p>
                </div>
                <div className="w-full mb-4">
                  <p className="w-full mb-1 text-sm font-montserrat font-light tracking-tight text-[#37e8dd]">SESSÕES QWERTY</p>
                </div>
                <div className="w-full pt-4 mb-6 text-sm font-montserrat font-light tracking-tight text-[#37e8dd]">
                  <img className="p-3 w-28 mx-auto mb-8" src={Modulo} alt="" />
                </div>

              </div>
              <div className="flex flex-col mx-2 md:flex-row md:flex-grow rounded-3xl bg-white border-2 border-[#37e8dd]">
                <div className="flex flex-col md:w-1/2 w-full">
                  <div className="p-2 text-center ">
                    <p className="w-full text-sm font-montserrat font-semibold tracking-tight text-black mt-2">AULAS:</p>
                  </div>
                  <AulasItem
                    locked={false}
                    isDone={true}
                    isChecked={true}
                    isActive={true}
                    title={'M1 - 01 | Mapas Felizes'}
                    description={'Os alunos vão criar algoritmos (conjuntos de instruções) para mover uma personagem através de um labirinto usando um único comando.'}
                  />
                  <AulasItem
                    locked={true}
                    isDone={false}
                    isChecked={false}
                    isActive={true}
                    title={'M1 - 02 | Mexe-te, Mexe-te'}
                    description={'Os alunos aprendem com dar instruções aos seus colegas para se que estes se desloquem num percurso na sala de aula. Interação com Robôs.'}
                  />
                  <AulasItem
                    locked={true}
                    isDone={false}
                    isChecked={false}
                    isActive={true}
                    title={'M1 - 03 | Jigsaw: Aprende a arrastar e soltar'}
                    description={'Os alunos ganham familiaridade com o computador resolvendo puzzles jigsaw, que os iriam habituar ao sistema do Code.org e também a idéia de drag & drop ...'}
                  />
                  <AulasItem
                    locked={false}
                    isDone={false}
                    isChecked={false}
                    isActive={true}
                    title={'M1 - 04 | Labirinto: Sequência'}
                    description={'Os alunos devem escrever programas (algoritmos para o computador) que levam a personagem pelo labirinto. Eles vão perceber a importância da sequência nos programas que escrevem. Interação de Robôs.'}
                  />
                  <AulasItem
                    locked={false}
                    isDone={false}
                    isChecked={false}
                    isActive={false}
                    title={'M1 - 05 | Labirinto: Debugging'}
                    description={'Usando o mesmo ambiente da sessão anterior, é apresentado aos alunos um labirinto com um programa pré-escrito que falha em levar a personagem ao seu objetivo.'}
                  />
                </div>
                <div className="flex flex-col sm:flex-grow md:border-t-0 rounded-tr-2xl rounded-br-2xl md:w-1/2 w-full">
                  <div className="md:border-l-2 border-l-0 border-[#37e8dd] h-full">
                    <div className="px-2 pb-2 text-center ">
                      <p className="w-full text-sm font-montserrat font-semibold tracking-tight text-black pt-2 mt-2">
                        PROJETOS:
                      </p>
                    </div>
                    <ProjectoItem
                      locked={false}
                      isDone={false}
                      isChecked={true}
                      isActive={true}
                      title={'Nome do Projeto'}
                      description={'text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500 ...'}
                    />
                    <ProjectoItem
                      locked={true}
                      isDone={false}
                      isChecked={false}
                      isActive={false}
                      title={'Nome do Projeto'}
                      description={'text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500 ...'}
                    />
                    <ProjectoItem
                      locked={true}
                      isDone={false}
                      isChecked={false}
                      isActive={false}
                      title={'Nome do Projeto'}
                      description={'text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500 ...'}
                    />
                    <ProjectoItem
                      locked={false}
                      isDone={false}
                      isChecked={false}
                      isActive={true}
                      title={'Nome do Projeto'}
                      description={'text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500. Lorem Ipsum has been the industry standard dummy text ever since 1500 ...'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        </>
    )
}
